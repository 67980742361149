import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";
import { setUrl } from "@/utils/common";

export const getPullData = (data:any): Promise<AxiosResponse<object>> =>{  
    // let url:string = setUrl('/agent-api/api/rank/index?',data)
    return service({
        method:'post',
        url:`${api}/agent-api/api/rank/index`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}