
import { defineComponent, onMounted, reactive, ref, toRefs,Ref,onBeforeMount } from "vue";
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
import 'dayjs/locale/zh-cn';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import type { Dayjs } from 'dayjs';
import { getTime } from '@/utils/common';
import { getPullData } from '@/api/saleManage/pullRanking'
export default defineComponent({
    name:"largeRanking",
    components:{
        breadcrumb
    },
    setup(){
       const month = ref<Dayjs>()
        const query = reactive({
            checked:0,
            month2:'',
            type:'sale',
        })
        const provincePage = reactive({
            page:1,
            pageSize:10,
            total:20
        })
        const cityPage = reactive({
            page:1,
            pageSize:10,
            total:20
        })
        const data = ref<any []>([
            {title:'首页',path:'#/'},
            {title:'销售管理',path:'/index/saleManage'},
            {title:'大排行',path:'#/'},
        ])
        const dataSource = reactive({
            cityList:[],
            provinceList:[],
            stationList:[],
            cCount:'',
            pCount:'',
            sCount:'',
        })
        const columns = ref<object []>([
            { title: '排名',dataIndex: 'ranking', key: 'ranking'},
            { title: '名称', dataIndex: 'name', key: 'name',},
            { title: '数量', dataIndex: 'count', key: 'count',},
        ])
        const columns2 = ref<object []>([
            { title: '排名',dataIndex: 'ranking', key: 'ranking'},
            { title: '驿站', dataIndex: 'city_name', key: 'city_name',},
            { title: '名称', dataIndex: 'name', key: 'name',},
            { title: '数量', dataIndex: 'count', key: 'count',},
        ])
        onBeforeMount(()=>{
            getPull()
        })

        // 大排行数据
        const getPull = (s_page?:number | '',c_page?:number):void =>{
            if(!month.value){
                query.month2 = '';
            }
            let data = {
                type:'sale',
                month:query.month2,
                year:query.checked?1:0,
                // s_page:s_page?s_page:'',
                // c_page:c_page?c_page:'',
                // per_page:10
            }
            getPullData(data).then((res:any)=>{
                if(res.code == 200){
                    console.log(res.data);
                    dataSource.cCount = res.c_count;
                    dataSource.pCount = res.p_count;
                    dataSource.sCount = res.s_count;
                    dataSource.cityList = res.data.city_sale_list;
                    dataSource.provinceList = res.data.province_sale_list;
                    dataSource.stationList = res.data.stage_sale_list;
                    provincePage.total = res.p_sale_count;
                    cityPage.total = res.c_sale_count;
                    
                    for (let i = 0; i < dataSource.cityList.length; i++) {
                        (dataSource.cityList[i] as any).ranking = i+1;
                    }
                    for (let i = 0; i < dataSource.provinceList.length; i++) {
                        (dataSource.provinceList[i] as any).ranking = i+1;
                    }
                    for (let i = 0; i < dataSource.stationList.length; i++) {
                        (dataSource.stationList[i] as any).ranking = i+1;
                    }                    
                }
            })
        }
        // 本年度
        const checkChange = ():void =>{
            getPull(provincePage.page)
        }
        // 时间发生变化
        const panelChange = (val:any) =>{
            if(val){
                query.month2 = getTime(month.value);
                getPull()
            }
        }
        // 表格分页
        const tableChange = (page:any):void =>{
            provincePage.page = page.current;
            provincePage.pageSize = page.pageSize;
            getPull(provincePage.page)
        } 
        const cityTableChange = (page:any):void =>{
            cityPage.page = page.current;
            cityPage.pageSize = page.pageSize;
            getPull('',cityPage.page)
        }

        return{
            data,
            locale,
            month,
            columns,
            cityPage,
            columns2,
            ...toRefs(query),
            ...toRefs(dataSource),
            ...toRefs(provincePage),
            panelChange,
            checkChange,
            tableChange,
            cityTableChange
        }
    }
})
